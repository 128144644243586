import { getTrackings } from "../services/apiService";
import { 
    goBackward,
    defaultFilters,
    icons,
    numberWithSpaces,
    config,
    getIconBasedOnCalc,
    defaultPreferences
} from "../utils/tracking-data.js"
import { useState, useEffect } from 'react'
import Filters from '../components/filters/filters.js'

let firstTime = true

const TrackingPage = () => {

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        sessions: [0, 0],
        pages: [0, 0],
        sales: [0, 0],
        pax: [0, 0],
        bookings: [0, 0],
        salePerVisitor: [0, 0],
        conversion: [0, 0],
        unfinished: [0, 0]
    })
    const [filters, setFilters] = useState(localStorage.getItem('tracking-filters') ? JSON.parse(localStorage.getItem('tracking-filters')) : defaultFilters)
    const [preferences, setPreferences] = useState(localStorage.getItem('tracking-filters-preferences') ? JSON.parse(localStorage.getItem('tracking-filters-preferences')) : defaultPreferences)


    const getTrackingRawData = async () => {

        setLoading(true)

        const createdAtLast = goBackward(filters.mode, filters.createdAt)
        let res = await getTrackings({...filters, createdAtLast: createdAtLast})
        if (!res.data) return
        else res = res.data

        setData((p) => {

            let ns = {...p}

            ns.sessions[0] = numberWithSpaces(res.current.sessions,0) || 0
            ns.sessions[1] = getIconBasedOnCalc('default', res.current.sessions, res.last.sessions)

            ns.pax[0] = numberWithSpaces(Number(res.current.pax),0) || 0
            ns.pax[1] = getIconBasedOnCalc('default', res.current.pax, res.last.pax)

            ns.pages[0] = numberWithSpaces(res.current.pages,0) || 0
            ns.pages[1] = getIconBasedOnCalc('default', res.current.pages, res.last.pages)

            ns.sales[0] = numberWithSpaces(res.current.sales || 0) + '€'
            ns.sales[1] = getIconBasedOnCalc('default', res.current.sales, res.last.sales)

            ns.bookings[0] = numberWithSpaces(res.current.bookings,0) || 0
            ns.bookings[1] = getIconBasedOnCalc('default', res.current.bookings, res.last.bookings)

            ns.salePerVisitor[0] = numberWithSpaces(res.current.sessions ? (res.current.sales / res.current.sessions) : 0) + '€'
            ns.salePerVisitor[1] = getIconBasedOnCalc('salePerVisitor', res.current.sales, res.current.sessions, res.last.sales, res.last.sessions)

            ns.conversion[0] = numberWithSpaces((res.current.sessions ? (res.current.bookings / res.current.sessions) : 0) * 100) + '%'
            ns.conversion[1] = getIconBasedOnCalc('conversion', res.current.bookings, res.current.sessions, res.last.bookings, res.last.sessions)

            ns.unfinished[0] = numberWithSpaces((res.current.startedBookings ? ((res.current.startedBookings - res.current.bookings) / res.current.startedBookings) : 0) * 100) + '%'
            ns.unfinished[1] = getIconBasedOnCalc('unfinished', res.current.startedBookings, res.current.bookings, res.last.startedBookings, res.last.bookings)

            return ns
        })
        
        setLoading(false)
    }

    useEffect(()=>{
        if (firstTime) {
            firstTime = false
            return
        }
        getTrackingRawData()
        localStorage.setItem('tracking-filters', JSON.stringify(filters))
    },[filters])

    return (
        <div className='card trackings_page'>
            <div className='card-body'>
                <div className='tracking_page_header'><p className='page_title'>TRACKINGS</p></div>
                <div className="controls">
                    <Filters 
                        config={config}
                        onFilterChange={(e) => setFilters({...filters, ...e})}
                        onPreferenceChange={(e) => setPreferences(e)}
                        disable={loading}
                    />
                </div>


                <div className="trackings_container">
                    { loading && (<div className="loader_container"><span className="loader"></span></div>)}
                    <div className="trackings">
                        <p><span className="ti ti-eye"></span>Visitas</p>
                        <p>
                            <span className={`ti ${icons[data.sessions[1]]}`}></span>
                            <span>{data.sessions[0]}</span>
                        </p>
                    </div>
                    <div className="trackings">
                        <p><span className="ti ti-file"></span>Paginas visitadas</p>
                        <p>
                            <span className={`ti ${icons[data.pages[1]]}`}></span>
                            <span>{data.pages[0]}</span>
                        </p>
                    </div>
                    <div className="trackings">
                        <p><span className="ti ti-friends"></span>Nº de reservas</p>
                        <p>
                            <span className={`ti ${icons[data.bookings[1]]}`}></span>
                            <span>{data.bookings[0]}</span>
                        </p>
                    </div>
                    <div className="trackings">
                        <p><span className="ti ti-ticket"></span>Nº de tickets</p>
                        <p>
                            <span className={`ti ${icons[data.pax[1]]}`}></span>
                            <span>{data.pax[0]}</span>
                        </p>
                    </div>
                    <div className="trackings">
                        <p><span className="ti ti-coins"></span>Total vendido</p>
                        <p>
                            <span className={`ti ${icons[data.sales[1]]}`}></span>
                            <span>{data.sales[0]}</span>
                        </p>
                    </div>
                    <div className="trackings">
                        <p><span className="ti ti-user"></span>Venta potencial por visitante</p>
                        <p>
                            <span className={`ti ${icons[data.salePerVisitor[1]]}`}></span>
                            <span>{data.salePerVisitor[0]}</span>
                        </p>
                    </div>
                    <div className="trackings">
                        <p><span className="ti ti-graph"></span>Porcentaje de Conversión</p>
                        <p>
                            <span className={`ti ${icons[data.conversion[1]]}`}></span>
                            <span>{data.conversion[0]}</span>
                        </p>
                    </div>
                    <div className="trackings">
                        <p><span className="ti ti-mood-sad"></span>Porcentaje de abandono</p>
                        <p>
                            <span className={`ti ${icons[data.unfinished[1]]}`}></span>
                            <span>{data.unfinished[0]}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrackingPage