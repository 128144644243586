import moment from 'moment'
import 'moment/locale/es'

moment.locale('es')


const defaultFilters = {
	createdAt: ['', ''],
	updatedAt: ['', ''],
	venues: 0,
	type: [],
	status: [],
	userId: [],
	zone: [],
	productId: []
}

const defaultUserPreferences = {autoFilters: 0}

const numberWithSpaces = (x) => {
	const n = String(x.toFixed(2)).split('.')
	return n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ',' + n.slice(1)
}
const priceFormatter = (props, attr) => {
	return <span className="price-tag">{props[attr] ? numberWithSpaces(props[attr]) + '€' : '0'}</span>
}



const columnsBookingsLines = [
	{
		name: 'id',
		selector: (row) => row.id,
		sortable: true,
		width: '60px',
	},
	{
		name: 'desc',
		selector: (row) => row.desc,
		sortable: true,
		width: '160px',
	},
	{
		name: 'type',
		selector: (row) => row.type,
		sortable: true,
		width: '60px',
	},
	{
		name: 'category',
		selector: (row) => row.category,
		sortable: true,
		width: '60px',
	},
	{
		name: 'zone',
		selector: (row) => row.zone,
		sortable: true,
		width: '60px',
	},
	{
		name: 'qty',
		selector: (row) => row.qty,
		sortable: true,
		width: '60px',
	},
	{
		name: 'price',
		selector: (row) => row.price,
		sortable: true,
		cell: (props) => (props.price ? props.price.toFixed(2) + ' €' : ''),
		width: '60px',
	},
	{
		name: 'disc',
		selector: (row) => row.disc,
		sortable: true,
		width: '60px',
	},
	{
		name: 'bookingId',
		selector: (row) => row.bookingId,
		sortable: true,
		width: '60px',
	},
	{
		name: 'createdAt',
		selector: (row) => row.createdAt,
		sortable: true,
		cell: (props) => (props.createdAt ? moment(props.createdAt).fromNow() : ''),
	},
	{
		name: 'productId',
		selector: (row) => row.productId,
		sortable: true,
	},
]

const columnsBookinfsLinesMetrics = [
	{
		name: 'producto',
		field: 'productId',
		selector: (row) => row.productId,
		sortable: true,
		width: 'clacl(100% - (3 * 90px))',

	},
	{
		name: 'zonas',
		field: 'zone',
		selector: (row) => row.zone,
		sortable: true,
		width: 'clacl(100% - (3 * 90px))',

	},
	{
		name: 'tipos',
		field: 'type',
		selector: (row) => row.type,
		sortable: true,
		width: 'clacl(100% - (3 * 90px))',

	},
	{
		name: 'cantidad',
		field: 'qty',
		selector: (row) => row.qty,
		sortable: true,
		width: '90px',
		right: true,
	},
	{
		name: 'cout',
		field: 'cout',
		selector: (row) => row.cout,
		sortable: true,
		width: '90px'
	},
	{
		name: 'Ventas',
		field: 'price',
		selector: (row) => row.price,
		cell: (props) => priceFormatter(props, 'price'),
		width: '90px',
		right: true,
		sortable: true,
		style: {
			paddingRight: '4px'
		}
	},
]

export { 
	columnsBookingsLines, 
	columnsBookinfsLinesMetrics,
	defaultFilters,
	defaultUserPreferences
}

