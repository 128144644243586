
const translateModels = {
    booking: 'Reserva',
    bookingLine: 'Linea de Reserva',
    device: 'Dispositivo',
    event: 'Evento',
    origin: 'Origen',
    payment: 'Pago',
    product: 'Producto',
    modifier: 'Modificador',
    shift: 'Pase',
    table: 'Mesa',
    user: 'Usuario',
    venue: 'Local',
}

export default translateModels