
import React, { useState, useEffect } from 'react'
import InputSelectOption from './InputSelectOption'

const InputSelect = ({
    name = 'mySelect',
    selectId = "mySelect",
    label = '',
    labelPos = "left",
    placeholder = 'Seleccionar...',
    defaultValue = [], 
    options = [],
    tags = [],
    disable = false,
    searchable = false,
    showCurrentValue = false,
    showCheckedValuesTag = false,
    multiSelect = false,
    selectAllOption = false,
    autoHide = false,
    toggleSelects = false,
    onlyOne = false,
    clearFilters = false,
    setTags = ()=>{},
    setOptions = ()=>{},
    onInput = (e) => {},
    onSelectOpened = (e) => {}
}) => {

    
    const [focused, setFocused] = useState(false)
    const [checkedValues, setCheckedValues] = useState(
            defaultValue ? Array.isArray(defaultValue) ? defaultValue : 
                [defaultValue] : []
    )
    const [lastOptions, setLastOptions] = useState(options.length)
    const [inputValue, setInputValue] = useState( 
        showCurrentValue && defaultValue!==undefined && ['number', 'string'].includes(typeof defaultValue) ? 
            options.find((x)=>x.value==defaultValue)?.name || placeholder : placeholder 
    )
    const [overlayStyle, setOverlayStyle] = useState({})
    const [search, setSearch] = useState('')

    const hideOverlay = ()   => setFocused((prev)=> autoHide ? false : prev) 
    const handleSearch = (e) => setSearch(e.target.value)
    const handleFocus = (e)  => setFocused((prev)=> !prev)

    const updateOverlayPosition = () => {
        let hasEnoughSpace = true 
        const mySelect = document.getElementById(selectId);
        if (mySelect) hasEnoughSpace = mySelect.getBoundingClientRect().y + 298 < window.visualViewport.height
        setOverlayStyle(hasEnoughSpace ? { top: "calc(100% + 8px)" } : { bottom: "calc(100% + 8px)" });
    }
    const handleClickOutSide = (e) => {
        const thisSelect = document.getElementById(selectId)
        if (thisSelect && !thisSelect.contains(e.target)) setFocused(false)
    }
    const labelPosition = 
        labelPos==='center' ? { left: "50%", transform: "translateX(-50%)", bottom: "40px" } : 
        labelPos==='right' ? { right: "5px", bottom: "40px" }  :
        labelPos==='start' ? { left: `-${label.length*7.5}px` }
        : { left: "5px", bottom:"40px" } 
        
    useEffect(()=>{
        
        setSearch('')
        updateOverlayPosition()
        
        const optionsContainer = document.querySelector(`#${selectId}.input-container .input-options-container`)
        if (optionsContainer) {
            optionsContainer.scrollTo({
				left: 0,
				top: 0,
				behavior: "instant",
			})
        }

        setOptions((prev) => {
            return prev.sort((a,b) => {
                // first selected options then alphabetical
                if (checkedValues.includes(a.value) && !checkedValues.includes(b.value)) return -1;
                if (!checkedValues.includes(a.value) && checkedValues.includes(b.value)) return 1;
                return a.name.localeCompare(b.name);
            })
        })

        onSelectOpened({
            target: {
                name: name,
                id: selectId, 
                value: multiSelect ? 
                    JSON.parse(JSON.stringify(checkedValues)) : 
                    JSON.parse(JSON.stringify(checkedValues))[0],
                opened: focused ? true : false
            }
        })
        
        window.addEventListener("scroll", updateOverlayPosition)
        if (toggleSelects) document.addEventListener("click", handleClickOutSide)
        
        return ()=> {
            window.removeEventListener("scroll", updateOverlayPosition)
            document.removeEventListener("click", handleClickOutSide)
        }
    },[focused])

    const handleInputEvent = (setter) => {

        let newCheckedValuesState = []
        if (typeof setter === 'function') {
            newCheckedValuesState = setter(checkedValues)
        } else newCheckedValuesState = setter

        onInput({
            target: {
                name: name,
                id: selectId, 
                value: multiSelect ? 
                    JSON.parse(JSON.stringify(newCheckedValuesState)) : 
                    JSON.parse(JSON.stringify(newCheckedValuesState))[0],
                opened: focused ? true : false
            }
        })

        setCheckedValues(newCheckedValuesState)

        if (!showCurrentValue) return;

        const newValue = options.find((x)=>x.value==newCheckedValuesState[0])
        if (newCheckedValuesState.length===0 && selectAllOption) setInputValue("Todos")
        else if (newValue) setInputValue(newValue.name)
        else setInputValue(placeholder)
        
    }

    useEffect(()=>{
        if (clearFilters > 0 && onlyOne) {
            setCheckedValues([defaultValue])
            const newValue = options.find((x)=>x.value==defaultValue)
            setInputValue(newValue.name)
        } else if (clearFilters > 0) {
            setCheckedValues([])
        }
    },[clearFilters])

    useEffect(()=>{
        if (options.length > 0 && lastOptions === 0){
            setInputValue(
                showCurrentValue && defaultValue!==undefined && ['number', 'string'].includes(typeof defaultValue) ? 
                options.find((x)=>x.value==defaultValue)?.name || placeholder : placeholder 
            )
            setOptions((prev) => {
                return prev.sort((a,b) => {
                    // first selected options then alphabetical
                    if (checkedValues.includes(a.value) && !checkedValues.includes(b.value)) return -1;
                    if (!checkedValues.includes(a.value) && checkedValues.includes(b.value)) return 1;
                    return a.name.localeCompare(b.name);
                })
            })
            setLastOptions(options.length)
        }
    }, [options])

    return (
        <div id={selectId} className={`input-container ${disable ? 'disable' : ''}`}>
            { label.length>0 && (<p className='input-label' style={labelPosition}>{label}</p>)}
            <div className='input-value-container' onClick={handleFocus}>
                <p className={`input-value ${checkedValues.length===0 ? "unsetted" : ""}`}>{inputValue}</p>
                <div className='input-value-right'>
                    {( showCheckedValuesTag && checkedValues.length!==0) && (
                        <div>
                            <p>{checkedValues.length}</p>
                        </div>
                    )}
                    <i className={`${ focused ? " down " : " up " } ti ti-arrow-down input-icon`}></i>
                </div>
            </div>

            <div 
                id={selectId + "-overlay"} 
                className={`input-overlay ${focused ? "focused" : ""}`} 
                style={overlayStyle} 
            >
                { searchable && (
                    <input type='text' onInput={handleSearch} placeholder='Buscar...' value={search}/>
                )}
                { tags.length>0  && (
                    <ul className='input-tags-list'>
                        {
                        tags.map((tag, i) => {
                            return (
                                <li className={`${tag.name} ${tag.active===true ? "active" : "" }`} key={i} onClick={() => {
                                    handleInputEvent(tag.values);
                                    setTags((prev) => {
                                        let newArr = [...prev]
                                        newArr.forEach((tag) => tag.active = false)
                                        newArr[i].active ? newArr[i].active = false : newArr[i].active = true // toggle tags
                                        return newArr
                                    })
                                }}>
                                    <p>{tag.name}</p> 
                                    <span className={`${tag.icon}`}></span>
                                </li>
                            )
                        })
                        }
                    </ul>
                )}
                <ul className='input-options-container'>
                    { selectAllOption && (
                        <>
                        <InputSelectOption 
                            value={NaN}
                            innerValue={"Todos"}
                            setCheckedValues={handleInputEvent}
                            checked={(checkedValues.length===0 && selectAllOption)}
                            multiSelect={multiSelect}
                            hideOverlay={hideOverlay}
                            setTags={setTags}
                            onlyOne={onlyOne}
                            selectAllOption={selectAllOption}
                        />
                        <hr />
                        </>
                    )}
                    { options.length>0 && (
                        options
                        .map((option,i)=>{
                            if (search.length>0 && !option.name.toLowerCase().includes(search.toLowerCase())) return null // search
                            return (
                                <InputSelectOption 
                                    key={option.value + "-" + i}
                                    value={option.value}
                                    innerValue={option.name}
                                    setCheckedValues={handleInputEvent}
                                    checked={checkedValues.find((v) =>v ==option.value)}
                                    multiSelect={multiSelect}
                                    hideOverlay={hideOverlay}
                                    setTags={setTags}
                                    onlyOne={onlyOne}
                                    selectAllOption={selectAllOption}
                                />
                            )
                        })
                    )}
                </ul>
            </div>  
        </div>
    );
};
export default InputSelect;
