const AsideComponent = () => {
	return (
		<aside className="left-sidebar">
			{
				//  Sidebar scroll
			}
			<div>
				<div className="brand-logo d-flex align-items-center justify-content-between">
					<a href="./index.html" className="text-nowrap logo-img">
						<img src="/images/rumba.png" className="dark-logo" width="38" height="38" alt="Logo" />
						<img
							src="/images/rumba.svg"
							className="dark-logo"
							width="60"
							alt="Rumba"
							style={{ marginLeft: 12 }}
						/>
					</a>
					<div className="close-btn d-lg-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
						<i className="ti ti-x fs-8"></i>
					</div>
				</div>
				{
					//  Sidebar navigation
				}
				<nav className="sidebar-nav scroll-sidebar" data-simplebar>
					<ul id="sidebarnav">
						<li className="nav-small-cap">
							<i className="ti ti-dots nav-small-cap-icon fs-4"></i>
							<span className="hide-menu">Reservas</span>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/" aria-expanded="false">
								<span>
									<i className="ti ti-home"></i>
								</span>
								<span className="hide-menu">Inicio</span>
							</a>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/sales" aria-expanded="false">
								<span>
									<i className="ti ti-ticket"></i>
								</span>
								<span className="hide-menu">Ventas</span>
							</a>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/bookings-lines" aria-expanded="false">
								<span>
									<i className="ti ti-notes"></i>
								</span>
								<span className="hide-menu">Líneas de producto</span>
							</a>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/events" aria-expanded="false">
								<span>
									<i className="ti ti-calendar"></i>
								</span>
								<span className="hide-menu">Eventos</span>
							</a>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/status" aria-expanded="false">
								<span>
									<i className="ti ti-theater"></i>
								</span>
								<span className="hide-menu">Estado tablao</span>
							</a>
						</li>
						{
							//  =============================
							//  Facturación
							//  =============================
						}
						<li className="nav-small-cap">
							<i className="ti ti-dots nav-small-cap-icon fs-4"></i>
							<span className="hide-menu">Facturación</span>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/payments" aria-expanded="false">
								<span>
									<i className="ti ti-coin-euro"></i>
								</span>
								<span className="hide-menu">Pagos</span>
							</a>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/cashiers" aria-expanded="false">
								<span>
									<i className="ti ti-building-bank"></i>
								</span>
								<span className="hide-menu">Caja</span>
							</a>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/comisions" aria-expanded="false">
								<span>
									<i className="ti ti-discount"></i>
								</span>
								<span className="hide-menu">Comisiones</span>
							</a>
						</li>
						{
							//  =============================
							//  Configuración
							//  =============================
							/*
- Inventario
- Usuarios
- Pases
- Mesas
- Dispositivos
- Orígenes
- Tablaos
- Logs
                */
						}
						<li className="nav-small-cap">
							<i className="ti ti-dots nav-small-cap-icon fs-4"></i>
							<span className="hide-menu">Configuración</span>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/products" aria-expanded="false">
								<span>
									<i className="ti ti-packages"></i>
								</span>
								<span className="hide-menu">Inventario</span>
							</a>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/users" aria-expanded="false">
								<span>
									<i className="ti ti-users"></i>
								</span>
								<span className="hide-menu">Usuarios</span>
							</a>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/shifts" aria-expanded="false">
								<span>
									<i className="ti ti-clock-play"></i>
								</span>
								<span className="hide-menu">Pases</span>
							</a>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/tables" aria-expanded="false">
								<span>
									<i className="ti ti-armchair"></i>
								</span>
								<span className="hide-menu">Mesas</span>
							</a>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/devices" aria-expanded="false">
								<span>
									<i className="ti ti-device-ipad"></i>
								</span>
								<span className="hide-menu">Dispositivos</span>
							</a>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/origins" aria-expanded="false">
								<span>
									<i className="ti ti-radar"></i>
								</span>
								<span className="hide-menu">Orígenes</span>
							</a>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/venues" aria-expanded="false">
								<span>
									<i className="ti ti-pin"></i>
								</span>
								<span className="hide-menu">Tablaos</span>
							</a>
						</li>
						<li className="sidebar-item" >
							<a className="sidebar-link" href="/logs" aria-expanded="false">
								<span>
									<i className="ti ti-database"></i>
								</span>
								<span className="hide-menu">Logs</span>
							</a>
						</li>
						<li className="sidebar-item" style={{marginBottom: '40px'}}>
							<a className="sidebar-link" href="/tracking" aria-expanded="false">
								<span>
									<i className="ti ti-chart-infographic"></i>
								</span>
								<span className="hide-menu">Tracking</span>
							</a>
						</li>
					</ul>
					<div className=""></div>
				</nav>
				<div className="fixed-profile p-3 bg-light-secondary rounded sidebar-ad mt-3">
					<div className="hstack gap-3">
						<div className="john-img">
							<img
								src="/images/profile/user-1.jpg"
								className="rounded-circle"
								width="40"
								height="40"
								alt=""
							/>
						</div>
						<div className="john-title">
							<h6 className="mb-0 fs-4 fw-semibold">Mathew</h6>
							<span className="fs-2 text-dark">Designer</span>
						</div>
						<button
							className="border-0 bg-transparent text-primary ms-auto"
							tabIndex="0"
							type="button"
							aria-label="logout"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
							data-bs-title="logout"
						>
							<i className="ti ti-power fs-6"></i>
						</button>
					</div>
				</div>
				{
					//  End Sidebar navigation
				}
			</div>
			{
				//  End Sidebar scroll
			}
		</aside>
	)
}

export default AsideComponent
