//import { stringify } from 'uuid'
// const apiRoot = 'http://localhost:3080/api-pre/' // LOCAL
const apiRoot = 'https://rumba.cardamomo.com/api-pre/' // PRE
//const apiRoot = 'https://rumba.cardamomo.com/api/' // PROD
const apiRootBackoffice = `${apiRoot}backoffice/`
const apiLiveRoot = 'https://rumba.cardamomo.com/api-pre/live/'

const parseFilters = (f) => {
	return Object.keys(f)
		.map((key) => {
			const filter = f[key]
			if (!filter || filter === '') return ''
			const v = Array.isArray(filter) ? filter : [filter]
			return `&${key}=${v.join(',')}`
		})
		.join('')
}

const getDataApi = async (
	venueId,
	model,
	offset = 0,
	limit = 100,
	field = null,
	value = null,
	sortField = null,
	order = 'DESC',
	search = null,
	filters = null,
	format = null
) => {
	//https://rumba.cardamomo.com/api/backoffice/1/raw?limit=5&offset=0&model=user&sort=createdAt&order=DESC&bookingId=1
	if (value && !Array.isArray(value)) {
		value = [value]
	}
	const searchParam = search ? `&keyword=${search}` : ''
	const extraParams = field && value ? `&${field}=${value.join(',')}` : ''
	const sortParam = sortField ? sortField : 'id'
	const formatParam = format ? `&format=${format}` : ''
	//const dateParam = filters && filters.date ? `&date=${filters.date.join(',')}` : ''
	//const createdAtParam = filters && filters.createdAt ? `&createdAt=${filters.createdAt.join(',')}` : ''

	const extraFilters = filters == null ? '' : parseFilters(filters)

	const result = await apiWrapper(
		apiRootBackoffice,
		`${venueId}/${
			filters && (filters.metrics === 'zone' || filters.metrics === 'sellerPerformance') ? 'zones' : 'raw'
		}?limit=${limit}&offset=${offset}&model=${model}&sort=${sortParam}&order=${order}${extraParams}${searchParam}${formatParam}${extraFilters}`
	)
	return result
}
const getTrackings = async (filters) => {
	filters = filters ? parseFilters(filters) : ''
	const result = await apiWrapper(apiRootBackoffice, `trackings?filters=${filters}`)
	return result
}

const downloadApi = async (
	venueId,
	model,
	offset = 0,
	limit = 100,
	field = null,
	value = null,
	sortField = null,
	order = 'DESC',
	search = null,
	filters = null,
	format = 'xls'
) => {
	//https://rumba.cardamomo.com/api/backoffice/1/raw?limit=5&offset=0&model=user&sort=createdAt&order=DESC&bookingId=1

	const searchParam = search ? `&keyword=${search}` : ''
	const extraParams = field && value ? `&${field}=${value}` : ''
	const sortParam = sortField ? sortField : 'id'
	const formatParam = format ? `&format=${format}` : ''
	const extraFilters =
		filters == null
			? ''
			: Object.keys(filters).map((key) => {
					const filter = filters[key]
					if (!filter || filter === '') return ''
					const v = Array.isArray(filter) ? filter : [filter]
					return `&${key}=${v.join(',')}`
			  })

	return dlWrapper(
		apiRootBackoffice,
		`${venueId}/raw?limit=${limit}&offset=${offset}&model=${model}&sort=${sortParam}&order=${order}${extraParams}${searchParam}${formatParam}${extraFilters}`
	)
}
const doLogin = async (user, password, code, remember) => {
	const result = await apiWrapper(apiRootBackoffice, `login`, 'POST', { user, password, code, remember })
	return result
}
const getDashboardApi = async (venueId, from = null, to = null) => {
	//https://rumba.cardamomo.com/api/backoffice/1/raw?limit=5&offset=0&model=user&sort=createdAt&order=DESC
	const result = await apiWrapper(apiRootBackoffice, `${venueId}/dashboard?from=${from}&to=${to}`)
	return result
}
const getCashiers = async (loggedinVenueId, filters) => {
	const { from, to, userId, venueId } = filters
	const result = await apiWrapper(apiRootBackoffice, `${venueId}/cashiers?from=${from}&to=${to}&userId=${userId}`)
	return result
}

const getModelById = async (venueId, model, id) => {
	//https://rumba.cardamomo.com/api/backoffice/1/raw-element?model=user&id=3
	const result = await apiWrapper(apiRootBackoffice, `${venueId}/raw-element?model=${model}&id=${id}`)
	return result
}
const getOptions = async (key) => {
	const result = await apiWrapper(apiRootBackoffice, `options/${key}`)
	return result
}

const getRumbaVars = async () => {
	//https://rumba.cardamomo.com/api/backoffice/1/raw-element?model=user&id=3
	const result = await apiWrapper(apiRoot, 'venue/vars')
	return result
}
const updateModelById = async (venueId, model, id, data) => {
	//https://rumba.cardamomo.com/api/backoffice/1/raw-element?model=user&id=3
	const result = await apiWrapper(apiRootBackoffice, `${venueId}/raw-element?model=${model}&id=${id}`, 'POST', data)
	return result
}

const getBookingLogs = async (venueId, bookingId) => {
	//https://rumba.cardamomo.com/api/venue/1/bookings/6557/log
	const result = await apiWrapper(apiRoot, `venue/${venueId}/bookings/${bookingId}/log`)
	return result
}

const getLiveContentData = async () => {
	const result = await apiWrapper(apiLiveRoot, `data?lang=es`)
	return result
}

const uploadFile = async (file, model, field) => {
	const formData = new FormData()
	formData.append('model', model)
	formData.append(field, file)

	const result = await apiWrapperUpload(apiRootBackoffice, `upload`, 'POST', formData)
	//console.log(result)
	return result
}

const apiWrapper = (root, path, method = 'GET', data = null) => {
	const requestObject = {
		method: method,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	}
	if (data) {
		requestObject.body = JSON.stringify(data)
	}
	return fetch(`${root}${path}`, requestObject)
		.then((response) => response.json())
		.then((response) => {
			//console.log('Success:', response)
			if (response.result === 'nok' && response.errorCode === 401) {
				localStorage.removeItem('token')
				localStorage.removeItem('user')
				window.location.reload()
				return false
			}
			return response
		})
		.catch((error) => {
			console.error('Error: ', error)
			localStorage.removeItem('token')
			localStorage.removeItem('user')
			window.location.reload()
			return false
		})
}

const dlWrapper = (root, path, method = 'GET', data = null) => {
	const requestObject = {
		method: method,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
	}
	if (data) {
		requestObject.body = JSON.stringify(data)
	}
	return fetch(`${root}${path}`, requestObject)
}

const apiWrapperUpload = (root, path, method = 'POST', data = null) => {
	const requestObject = {
		method: method,
		body: data,
	}
	return fetch(`${root}${path}`, requestObject)
		.then((response) => response.json())
		.then((response) => {
			return response
		})
		.catch((error) => {
			console.error('Error: ', error)
			return error
		})
}

export {
	getDataApi,
	doLogin,
	getModelById,
	getDashboardApi,
	getBookingLogs,
	updateModelById,
	uploadFile,
	getRumbaVars,
	getCashiers,
	downloadApi,
	getOptions,
	getLiveContentData,
	getTrackings,
}
