import DataTableComponent from "../components/DataTableComponent"
import InputSelect from "../components/InputSelect/InputSelect.js" 
import { columnsOrigins } from "../utils/origins-data"
import React, { useState, useEffect } from 'react'
import { getDataApi } from "../services/apiService"
import useApp from '../utils/useApp'
import { useNavigate } from 'react-router-dom'
import References from "../parts/OriginChildren"

const venueOpts = [
	{value: 0, name: "Todos"}, 
	{value: 1, name: "Cardamomo"}, 
	{value: 2, name: "1911"}, 
	{value: 3, name: "Rumba demo"},
]
const OriginPage = () => {

	const [dataOrigin, setDataOrigin] = useState([])
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(1000)
	const [sortedOrigins, setSortedOrigins] = useState({})
	const [venueOptions, setVenueOptions] = useState(venueOpts)

	const navigate = useNavigate()
	const {
		state:{ user },
		actions: { updateUser }
	} = useApp()

	const getOriginData = async (page, limit = 3000, sortDirection = 'asc', sortField = 'id', searchTerm = null) => {
		setLoading(true)
		const offset = (page-1) * limit
		const result = await getDataApi(user.lastVenue, 'origin', offset, limit, 'parent', null, sortField, sortDirection, searchTerm)
		
		const OriginDataResponse = Object.values(result.data)
		let newObj = {}
		let parentNames = []
		OriginDataResponse
			.sort((a,b)=> a.parent - b.parent)
			.forEach((origin) => {
				const dataFormat = {
					id: origin.id,
					name: origin.name,
					parent: origin.parent,
					order: origin.order,
					venueId: origin.venueId,
					createdAt: origin.createdAt,
					updatedAt: origin.updatedAt
				}
				if (origin.parent === 0) {
					newObj[origin.name] = newObj[origin.name] || [dataFormat]
					parentNames.push({name: origin.name, id: origin.id})
				} else {
					newObj[parentNames.find((x)=>x.id===origin.parent).name].push(dataFormat)
				}
			})
		setSortedOrigins(newObj)
		setDataOrigin(Object.values(newObj).map((parent) => {return parent[0]}))

		// .filter( (x) => x.parent == 0)
		// .map((origin) => {
		// 	return {
		// 	id: origin.id,
		// 	name: origin.name,
		// 	parent: origin.parent,
		// 	order: origin.order,
		// 	venueId: origin.venueId,
		// 	createdAt: origin.createdAt,
		// 	updatedAt: origin.updatedAt
		// 	}
		// })
		// setDataOrigin(OriginDataResponse)
		// console.log(OriginDataResponse)
		setTotalRows(result.count)
		setLoading(false)
	}

	const handleNew = (e) => {
		const toUrl = `/origin/edit/0`
		navigate(toUrl)
	}


	const handleVenueChange = (e) => {
		updateUser({ ...user, lastVenue: e.target.value })
	}

	useEffect(()=>{
		getOriginData(1, perPage, 'asc', 'parent', null)
	},[user.lastVenue])


	return (
		<div className="card">
			<div className="card-body">
				<div className="row">
					<div className="col-12">
						<div className="d-sm-flex justify-content-end">
						{/* <InputSelect 
							label="Tablao"
							name='venues'
							selectId='slct-tablao'
							options={venueOptions}
							setOptions={setVenueOptions}
							showCurrentValue={true}
							autoHide={true}
							onInput={handleVenueChange}
							defaultValue={user.lastVenue}
							onlyOne={true}
						/> */}
							<button type="button" className="btn rmb-btn-primary" onClick={handleNew}>
								Nuevo origen
							</button>
						</div>
						<DataTableComponent
							title="Orígenes de pagos"
							description="Listado de origenes del tablao"
							columns={columnsOrigins}
							data={dataOrigin}
							setPerPage={setPerPage}
							loading={loading}
							getData={getOriginData}
							totalRows={totalRows}
							perPage={perPage}
							model="origin"
							noPagination={true}
							expandableRows={true}
							expandableRowsComponent={({data}) => <References formData={sortedOrigins[data.name]}/>}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
export default OriginPage
