
const InputSelectOption = ({
    checked = false,
    value,
    innerValue = '',
    setCheckedValues,
    multiSelect,
    hideOverlay,
    setTags,
    onlyOne = false,
    selectAllOption,
}) => {

    const handleClick = () => {
        hideOverlay()
        setTags((prev) => {
            // reset tags to off when option is clicked
            let newArr = [...prev]
            newArr.forEach((tag)=>tag.active=false)
            return newArr
        })
        setCheckedValues((prev) => {
            const [isChecked,indexOfValue,NotNum] = [(prev.indexOf(value)!==-1), prev.indexOf(value), (isNaN(value) && typeof(value)==='number')]
            // if value is checked and is the only one OR the all options is selected, clean array
            if (onlyOne && selectAllOption && NotNum) return []
            if (!onlyOne && ((prev.length===1 && isChecked) || NotNum))  return []
            // if we only can select one, keep the array with our value
            if ( !multiSelect || onlyOne && !NotNum) return [value]
            // if value is checked and is not the all option, deselect it
            if (isChecked && !NotNum ) return prev.filter((v, i) => i !== indexOfValue);
            // normal case, check the value
            return [...prev, value]
        })
    }
    
    return (
        <li className={`input-option-container ${(selectAllOption ? checked : typeof checked === 'number') ? "checked" : ""}`} onClick={handleClick}>
            <p>{innerValue}</p>
            { (selectAllOption ? checked : typeof checked === 'number') && ( 
                <span className="ti ti-check">
                </span>
            )}
        </li>
    )
}

export default InputSelectOption
