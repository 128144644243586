import moment from 'moment'
import 'moment/locale/es'
import { getDataApi } from '../services/apiService'
moment.locale('es')
//------------------------------------------------------------------------------
//utils values
const shifts = [
	[12, '22:30', 3],
	[11, '21:00', 3],
	[10, '19:30', 3],
	[9, '18:00', 3],
	[8, '22:30', 2],
	[7, '21:00', 2],
	[6, '19:30', 2],
	[5, '18:00', 2],
	[4, '22:30', 1],
	[3, '21:00', 1],
	[2, '19:30', 1],
	[1, '18:00', 1],
	[13, 'Clases', 2],
	[14, '23:40', 2],
	[15, 'MICE', 3],
	[16, 'MICE', 2],
	[17, 'MICE', 1],
	[18, '23:40', 3],
	[19, '23:40', 1],
]
	.filter((shift) => shift[2] < 3)
	.map((shift) => {
		return { value: shift[0], name: `${shift[1]} - ${shift[2] === 1 ? 'cardamomo' : shift[2] === 2 ? '1911' : ''}` }
	})
const defaultFilters = {
	status: [],
	origin: [],
	sellerId: [],
	createdAt: ['', ''],
	date: ['', ''],
	shiftId: [],
	venues: 0,
	omitCloned: '',
}

const defaultUserPreferences = { autoFilters: 0, salesDensity: 0 }
const metricsOpts = [
	{ value: 'shiftId', name: 'Pase' },
	{ value: 'sellerId', name: 'Vendedor' },
	{ value: 'originParent', name: 'Origen 1' },
	{ value: 'origin', name: 'Origen 2' },
	{ value: 'status', name: 'Estado' },
	{ value: 'salesHour', name: 'Horas' },
	{ value: 'zone', name: 'Zona' },
	{ value: 'sellerPerformance', name: 'Rendimiento comercial' },
	{ value: 'creationDay', name: 'Día de creación' }, //will take 1 month current if empty else first date month
	{ value: 'eventDay', name: 'Día de evento' }, //will take 1 month current if empty else first date month
]

let originsOptions = [] //setted by filters row 3 slct-origin
let sellerOptions = [] //setted by filters row 3 slct-user

const monthMap = {
	ene: '00',
	feb: '01',
	mar: '02',
	abr: '03',
	may: '04',
	jun: '05',
	jul: '06',
	ago: '07',
	sep: '08',
	oct: '09',
	nov: '10',
	dic: '11',
}
//------------------------------------------------------------------------------
//bokings utils functions
const getShiftName = (shiftId) => {
	const shift = shifts.find((s) => s.value === shiftId)
	return shift ? shift.name : shiftId ? shiftId : 'open'
}
const priceFormatter = (props, attr) => {
	return <span className="price-tag">{props[attr] ? numberWithSpaces(props[attr]) + '€' : '0'}</span>
}
const numberWithSpaces = (x) => {
	const n = String(x.toFixed(2)).split('.')
	return n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ',' + n.slice(1)
}
const getStayLength = (dts) => {
	if (dts[0] === '' || dts[1] === '') return 0
	const diff = new Date(dts[1]).getTime() - new Date(dts[0]).getTime()
	return Math.ceil(diff / (1000 * 3600 * 24)) || 0
}
const sortByCreationDay = (a, b) => {
	const gm = a.eventDay !== undefined ? 'eventDay' : 'creationDay'
	if (a[gm].match(/^ \dº Semana [a-z]{3} [\d]{4}$/) && b[gm].match(/^ \dº Semana [a-z]{3} [\d]{4}$/)) {
		const [x, y, w, monthA, yearA] = a[gm].split(' ')
		const [f, d, c, monthB, yearB] = b[gm].split(' ')

		return Number(`${yearA}${monthMap[monthA]}${a[gm][1]}`) - Number(`${yearB}${monthMap[monthB]}${b[gm][1]}`)
	} else {
		return Number(a[gm].substring(0, 2)) - Number(b[gm].substring(0, 2))
	}
}
//------------------------------------------------------------------------------
//columns
const columnsBookings = [
	{
		name: 'id',
		field: 'id',
		selector: (row) => row.id,
		sortable: true,
		width: '70px',
	},
	{
		name: 'Pax',
		field: 'pax',
		selector: (row) => row.pax,
		sortable: true,
		width: '40px',
	},
	{
		name: 'Locator',
		field: 'locator',
		selector: (row) => row.locators,
		width: '100px',
		sortable: true,
	},
	{
		name: 'Payment Status',
		field: 'paymentStatus',
		selector: (row) => row.paymentStatus,
		width: '140px',
		cell: (props) => {
			if (props.paymentStatus === 'paid') {
				return (
					<span className="badge rmb-text-success rmb-border-success fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-check fs-4"></i>
						{props.paymentStatus}
					</span>
				)
			} else if (props.paymentStatus === 'refunded') {
				return (
					<span className="badge rmb-text-secondary rmb-border-secondary fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-arrow-back-up fs-4"></i>
						{props.paymentStatus}
					</span>
				)
			} else if (props.paymentStatus === 'pending' || props.paymentStatus === 'mice_pending') {
				return (
					<span className="badge rmb-text-warning rmb-border-warning fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-clock-hour-4 fs-3"></i>
						{props.paymentStatus}
					</span>
				)
			} else {
				return (
					<span className="badge border border-2 bg-light-info text-info fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						{props.paymentStatus}
					</span>
				)
			}
		},
		sortable: true,
	},
	{
		name: 'Filter',
		field: 'filter',
		width: '120px',
		selector: (row) => row.filter,
		cell: (props) => {
			if (props.filter === 'assigned') {
				return <span className="badge rmb-text-success rmb-bg-success-light fs-2">{props.filter}</span>
			} else if (props.filter === 'pending') {
				return <span className="badge rmb-text-warning rmb-bg-warning-light fs-2">{props.filter}</span>
			} else if (props.filter === 'open') {
				return <span className="badge text-white rmb-bg-default fs-2">{props.filter}</span>
			} else {
				return <span className="badge rmb-text-secondary rmb-bg-secondary-light fs-2">{props.filter}</span>
			}
		},
		sortable: true,
	},
	{
		name: 'Status',
		field: 'status',
		selector: (row) => row.status,
		width: '120px',
		cell: (props) => {
			if (props.status === 'seated') {
				return <span className="badge text-white rmb-bg-primary fs-2">{props.status}</span>
			} else if (props.status === 'reception') {
				return <span className="badge text-white rmb-bg-primary-light fs-2">{props.status}</span>
			} else if (props.status === 'pending') {
				return <span className="badge text-white rmb-bg-warning fs-2">{props.status}</span>
			} else if (props.status === 'init') {
				return <span className="badge text-white rmb-bg-default-light fs-2">{props.status}</span>
			} else if (props.status === 'confirmed') {
				return <span className="badge text-white rmb-bg-secondary fs-2">{props.status}</span>
			} else if (props.status === 'blocked') {
				return (
					<span className="badge text-white rmb-bg-warning fs-2 fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-ban fs-3"></i>
						{props.status}
					</span>
				)
			} else if (props.status === 'cancel') {
				return (
					<span className="badge text-white rmb-bg-danger fs-2 fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-x fs-3"></i>
						{props.status}
					</span>
				)
			} else if (props.status === 'moved') {
				return (
					<span className="badge text-white rmb-bg-danger fs-2 fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-arrow-guide fs-3"></i>
						{props.status}
					</span>
				)
			} else if (props.status === 'remove') {
				return (
					<span className="badge text-white rmb-bg-primary fs-2 fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-cicle-minus fs-3"></i>
						{props.status}
					</span>
				)
			} else if (props.status === 'noshow') {
				return (
					<span className="badge text-white rmb-bg-default-light fs-2 fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-cicle-minus fs-3"></i>
						{props.status}
					</span>
				)
			} else {
				return <span className="badge text-white rmb-bg-secondary fs-2">{props.status}</span>
			}
		},
		sortable: true,
	},
	{
		name: 'prod.',
		field: 'venueId',
		selector: (row) => row.venueId,
		sortable: true,
		width: '35px',
	},
	{
		name: 'crea.',
		field: 'venueFrom',
		selector: (row) => row.venueFrom + (row.venueFrom !== row.venueId ? '*' : ''),
		sortable: true,
		width: '35px',
	},
	{
		name: 'Date',
		field: 'date',
		selector: (row) => row.date,
		sortable: true,
		width: '130px',
		cell: (props) => (props.date ? moment(props.date).utc().format('DD-MM-YYYY HH:mm') : ''),
	},
	{
		name: 'Name',
		field: 'name',
		selector: (row) => row.name,
		width: '100px',
		sortable: true,
	},
	{
		name: 'Email',
		field: 'email',
		selector: (row) => row.email,
		width: '100px',
		sortable: true,
	},
	{
		name: 'Phone',
		field: 'phone',
		selector: (row) => row.phone,
		width: '100px',
		sortable: true,
	},
	{
		name: 'Country',
		field: 'country',
		selector: (row) => row.country,
		width: '60px',
		sortable: true,
	},
	{
		name: 'Origin',
		field: 'originParent',
		selector: (row) => row.originParent,
		width: '80px',
		sortable: true,
	},
	{
		name: 'Origin 2',
		field: 'origin',
		selector: (row) => row.origin,
		width: '80px',
		sortable: true,
	},
	{
		name: 'Tags',
		field: 'tags',
		selector: (row) => row.tags,
		width: '60px',
		sortable: true,
	},
	{
		name: 'Total',
		field: 'price',
		selector: (row) => row.price,
		cell: (props) => priceFormatter(props, 'price'),
		width: '80px',
		sortable: true,
	},
	{
		name: 'Sales amount',
		field: 'salesAmount',
		selector: (row) => row.salesAmount,
		cell: (props) => priceFormatter(props, 'salesAmount'),
		width: '80px',
		sortable: true,
	},
	{
		name: 'Aftersales',
		field: 'aftersalesAmount',
		selector: (row) => row.aftersalesAmount,
		cell: (props) => priceFormatter(props, 'aftersalesAmount'),
		width: '80px',
		sortable: true,
	},
	{
		name: 'Access',
		field: 'access',
		selector: (row) => row.access,
		width: '60px',
		sortable: true,
	},
	{
		name: 'Tables',
		field: 'tables',
		selector: (row) => row.tables,
		width: '160px',
		sortable: true,
	},
	{
		name: 'Shift',
		field: 'shiftId',
		selector: (row) => getShiftName(row.shiftId),
		width: '60px',
		sortable: true,
	},
	{
		name: 'Event Id',
		field: 'eventId',
		selector: (row) => row.eventId,
		width: '60px',
		sortable: true,
	},
	{
		name: 'Seller Id',
		field: 'sellerId',
		selector: (row) => row.sellerId,
		width: '60px',
		sortable: true,
	},
	{
		name: 'Device Id',
		field: 'deviceId',
		selector: (row) => row.deviceId,
		width: '60px',
		sortable: true,
	},
	{
		name: 'Created At',
		field: 'createdAt',
		selector: (row) => row.createdAt,
		width: '110px',
		sortable: true,
		cell: (props) => moment(props.createdAt).fromNow(),
	},
	{
		name: 'Updated At',
		field: 'updatedAt',
		selector: (row) => row.updatedAt,
		width: '110px',
		sortable: true,
		cell: (props) => {
			return moment(props.updatedAt).fromNow()
		},
	},
	{
		name: 'Notes',
		field: 'notes',
		selector: (row) => row.notes,
		sortable: true,
	},
	{
		name: 'Icons',
		field: 'icons',
		selector: (row) => row.icons,
		width: '80px',
		sortable: true,
	},
	{
		name: 'Meals',
		field: 'meals',
		selector: (row) => row.meals,
		width: '60px',
		sortable: true,
	},
	{
		name: 'Children',
		field: 'children',
		selector: (row) => row.children,
		width: '60px',
		sortable: true,
	},
]

const columnsBookingsMetrics = [
	{
		name: 'Payment Status',
		field: 'paymentStatus',
		selector: (row) => row.paymentStatus,
		cell: (props) => {
			if (props.paymentStatus === 'paid') {
				return (
					<span className="badge rmb-text-success rmb-border-success fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-check fs-4"></i>
						{props.paymentStatus}
					</span>
				)
			} else if (props.paymentStatus === 'refunded') {
				return (
					<span className="badge rmb-text-secondary rmb-border-secondary fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-arrow-back-up fs-4"></i>
						{props.paymentStatus}
					</span>
				)
			} else if (props.paymentStatus === 'pending' || props.paymentStatus === 'mice_pending') {
				return (
					<span className="badge rmb-text-warning rmb-border-warning fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-clock-hour-4 fs-3"></i>
						{props.paymentStatus}
					</span>
				)
			} else {
				return (
					<span className="badge border border-2 bg-light-info text-info fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						{props.paymentStatus}
					</span>
				)
			}
		},
		sortable: true,
	},
	{
		name: 'Status',
		field: 'status',
		selector: (row) => row.status,
		cell: (props) => {
			if (props.status === 'seated') {
				return <span className="badge text-white rmb-bg-primary fs-2">{props.status}</span>
			} else if (props.status === 'reception') {
				return <span className="badge text-white rmb-bg-primary-light fs-2">{props.status}</span>
			} else if (props.status === 'pending') {
				return <span className="badge text-white rmb-bg-warning fs-2">{props.status}</span>
			} else if (props.status === 'init') {
				return <span className="badge text-white rmb-bg-default-light fs-2">{props.status}</span>
			} else if (props.status === 'confirmed') {
				return <span className="badge text-white rmb-bg-secondary fs-2">{props.status}</span>
			} else if (props.status === 'blocked') {
				return (
					<span className="badge text-white rmb-bg-warning fs-2 fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-ban fs-3"></i>
						{props.status}
					</span>
				)
			} else if (props.status === 'cancel') {
				return (
					<span className="badge text-white rmb-bg-danger fs-2 fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-x fs-3"></i>
						{props.status}
					</span>
				)
			} else if (props.status === 'remove') {
				return (
					<span className="badge text-white rmb-bg-primary fs-2 fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-cicle-minus fs-3"></i>
						{props.status}
					</span>
				)
			} else if (props.status === 'noshow') {
				return (
					<span className="badge text-white rmb-bg-default-light fs-2 fw-semibold fs-2 d-inline-flex align-items-center gap-1">
						<i className="ti ti-cicle-minus fs-3"></i>
						{props.status}
					</span>
				)
			} else {
				return <span className="badge text-white rmb-bg-secondary fs-2">{props.status}</span>
			}
		},
		sortable: true,
	},
	{
		name: 'Origin',
		field: 'originParent',
		selector: (row) => row.originParent,
		cell: (props) => {
			const or = originsOptions.find((o) => o.value === props.originParent)
			return or ? or.name + ' (' + props.originParent + ')' : props.originParent
		},
		sortable: true,
	},
	{
		name: 'Origin 2',
		field: 'origin',
		selector: (row) => row.origin,
		cell: (props) => {
			const or = originsOptions.find((o) => o.value === props.origin)
			return or ? or.name + ' (' + props.origin + ')' : props.origin
		},
		sortable: true,
	},
	{
		name: 'Shift',
		field: 'shiftId',
		selector: (row) => getShiftName(row.shiftId),
		sortable: true,
	},
	{
		name: 'Seller Id',
		field: 'sellerId',
		selector: (row) => row.sellerId,
		cell: (props) => {
			const us = sellerOptions.find((s) => s.value === props.sellerId)
			return us ? us.name + ' (' + props.sellerId + ')' : props.sellerId
		},
		sortable: true,
	},
	{
		name: 'Sales Hour',
		field: 'salesHour',
		selector: (row) => row.salesHour,
		sortable: true,
	},
	{
		name: 'Día de creación',
		id: 'creationDay',
		field: 'creationDay',
		selector: (row) => row.creationDay,
		sortable: true,
		sortFunction: sortByCreationDay,
	},
	{
		name: 'Día de evento',
		id: 'eventDay',
		field: 'eventDay',
		selector: (row) => row.eventDay,
		sortable: true,
		sortFunction: sortByCreationDay,
	},
	{
		name: 'Pax',
		field: 'pax',
		selector: (row) => row.pax,
		sortable: true,
		width: '90px',
	},
	{
		name: 'Bookings',
		field: 'count',
		selector: (row) => row.count,
		sortable: true,
		width: '90px',
	},
	{
		name: 'Sales',
		field: 'salesAmount',
		selector: (row) => row.salesAmount,
		cell: (props) => priceFormatter(props, 'salesAmount'),
		width: '90px',
		sortable: true,
	},
	{
		name: 'Aftersales',
		field: 'aftersalesAmount',
		selector: (row) => row.aftersalesAmount,
		cell: (props) => priceFormatter(props, 'aftersalesAmount'),
		width: '90px',
		sortable: true,
	},
	/* group by zone */
	{
		name: 'Zona',
		field: 'zone',
		selector: (row) => row.zone,
		sortable: true,
	},
	{
		name: 'Entradas',
		field: 'tickets',
		selector: (row) => row.tickets,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Reservas',
		field: 'bookings',
		selector: (row) => row.bookings,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Eventos',
		field: 'events',
		selector: (row) => row.events,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Ocupado',
		field: 'percentage',
		selector: (row) => row.percentage,
		cell: (props) => props.percentage + '%',
		sortable: true,
		width: '100px',
	},
	{
		name: 'Ventas A',
		field: 'za',
		selector: (row) => row.za,
		width: '100px',
		sortable: true,
	},
	{
		name: 'Ventas V',
		field: 'zv',
		selector: (row) => row.zv,
		width: '100px',
		sortable: true,
	},
	{
		name: 'Ticket medio',
		field: 'average',
		selector: (row) => row.average,
		cell: (props) => priceFormatter(props, 'average'),
		sortable: true,
		width: '100px',
		right: 'true',
	},
	{
		name: 'Importe',
		field: 'price',
		selector: (row) => row.price,
		cell: (props) => priceFormatter(props, 'price'),
		sortable: true,
		width: '150px',
		right: 'true',
	},

	{
		name: '% zonas',
		field: 'zonesGraphic',
		selector: (row) => row.sellerId,
		cell: (props) => {
			const arr = [
				{ zone: 'z_v', percent: 100 * (props.zv / props.tickets) },
				{ zone: 'z_a', percent: 100 * (props.za / props.tickets) },
				{ zone: 'z_a2', percent: 100 * (props.za2 / props.tickets) },
				{ zone: 'z_b', percent: 100 * (props.zb / props.tickets) },
				{ zone: 'z_c', percent: 100 * (props.zc / props.tickets) },
				{ zone: 'z_-', percent: 100 * (props.unasigned / props.tickets) },
			]
			let [maxZone, maxPercent] = ['', 0]
			arr.forEach(({ zone, percent }) => {
				if (maxPercent < percent) {
					maxPercent = percent
					maxZone = zone
				}
			})

			return (
				<div className="zones_graphic tooltip_container">
					{arr.map(({ zone, percent }, i) => {
						return (
							<span
								className={zone}
								key={i}
								style={{
									width: `${zone === maxZone ? 100 : percent}%`,
									flexShrink: `${zone === maxZone ? 1 : 0}`,
								}}
							></span>
						)
					})}
					<div className="tooltiptext">
						{arr.map(({ zone, percent }) => {
							return (
								<>
									{zone.substring(2).toLocaleUpperCase() + ' ' + percent.toFixed(2) + '%'}
									<br />
								</>
							)
						})}
					</div>
				</div>
			)
		},
		sortable: true,
		width: '150px',
		right: 'true',
	},

	{
		name: 'Total',
		field: 'price',
		selector: (row) => row.price,
		cell: (props) => priceFormatter(props, 'price'),
		width: '150px',
		right: 'true',
		sortable: true,
	},
]

//------------------------------------------------------------------------------
//filters rows except row 1 (needs a function defined in bookingspage.js)
const filtersRow2 = [
	{
		id: 'slct-tablao',
		key: 'venues',
		type: 'select',
		role: 'filter',
		label: 'Tablao',
		preferences: ['onlyOne', 'autoHide', 'showCurrentValue', 'toggleSelects'],
		optionsAndTags: () => [
			[
				{ value: 0, name: 'Todos' },
				{ value: 1, name: 'Cardamomo' },
				{ value: 2, name: '1911' },
			],
			[],
		],
	},
	{ key: 'createdAt', type: 'datetime-local', label: 'Creación', role: 'filter' },
	{ key: 'date', type: 'datetime-local', label: 'Evento', role: 'filter' },
]

const filtersRow3 = [
	{
		id: 'slct-state',
		key: 'status',
		role: 'filter',
		type: 'select',
		placeholder: 'Estado',
		preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
		optionsAndTags: async () => [
			[
				{ value: 'blocked', name: 'blocked' },
				{ value: 'closed', name: 'closed' },
				{ value: 'cancel', name: 'cancel' },
				{ value: 'confirmed', name: 'confirmed' },
				{ value: 'init', name: 'init' },
				{ value: 'pending', name: 'pending' },
				{ value: 'removed', name: 'removed' },
				{ value: 'rececption', name: 'rececption' },
				{ value: 'seated', name: 'seated' },
				{ value: 'noshow', name: 'noshow' },
				{ value: 'moved', name: 'moved' },
			],
			[
				{
					name: 'ok',
					values: ['pending', 'confirmed', 'seated', 'reception', 'closed', 'moved'],
					icon: 'ti ti-mood-smile',
					active: false,
				},
				{
					name: 'ko',
					values: ['init', 'cancel', 'blocked', 'removed', 'noshow'],
					icon: 'ti ti-mood-sad',
					active: false,
				},
			],
		],
	},
	{
		id: 'slct-origin',
		key: 'origin',
		type: 'select',
		role: 'filter',
		placeholder: 'Origen',
		optionsAndTags: async () => {
			const result = await getDataApi(0, 'origin', 0, 3000, null, null, 'id', 'asc')
			const origins = Object.values(result.data).map((origin) => {
				return {
					id: origin.id,
					name: origin.name,
					parent: origin.parent,
					order: origin.order,
					venueId: origin.venueId,
				}
			})
			let tags = {}
			let options = []
			const parents = origins.filter((o) => o.parent === 0)
			origins.forEach((origin) => {
				options.push({ value: origin.id, name: origin.name || origin.id.toString() })
				let parent = parents.find((p) => p.id === origin.parent)
				if (parent) {
					const { name } = parent
					tags[name] = tags[name] || { name: name, values: [], icon: '', active: false }
					tags[name].values = [...tags[name].values, origin.id]
				}
			})
			originsOptions = options
			return [options, Object.values(tags)]
		},
		preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'searchable', 'toggleSelects'],
	},
	{
		id: 'slct-seller',
		key: 'sellerId',
		type: 'select',
		role: 'filter',
		placeholder: 'Vendedor',
		preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'searchable', 'toggleSelects'],
		optionsAndTags: async () => {
			const result = await getDataApi(0, 'user', 0, 1000)
			const userDataResponse = Object.values(result.data).map((user) => {
				return {
					id: user.id,
					name: user.name,
					role: user.role,
				}
			})
			const options = []
			userDataResponse
				.filter((user) => user.role !== 'waiter' && user.role !== 'live')
				.forEach((user) => {
					options.push({ value: user.id, name: user.name })
				})
			sellerOptions = options
			return [options, []]
		},
	},
	{
		id: 'slct-shift',
		key: 'shiftId',
		type: 'select',
		role: 'filter',
		placeholder: 'Pase',
		preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
		optionsAndTags: async () => [shifts, []],
	},
]
const filtersRow4 = [
	[
		{
			id: 'slct-omit',
			key: 'omitCloned',
			role: 'filter',
			type: 'select',
			preferences: ['onlyOne', 'autoHide', 'showCurrentValue', 'toggleSelects'],
			optionsAndTags: async () => [
				[
					{ value: '', name: 'Incluir clonadas' },
					{ value: '1', name: 'Quitar clonadas' },
				],
				[],
			],
		},
		{ key: 'autoFilters' },
		{ id: 'salesDensity', key: 'salesDensity', type: 'switch', role: 'preference', label: 'Gráfico' },
	],
	[{ key: 'clearFilters' }, { key: 'applyFilters' }],
]

export {
	columnsBookings,
	columnsBookingsMetrics,
	shifts,
	getShiftName,
	filtersRow2,
	filtersRow3,
	filtersRow4,
	defaultFilters,
	defaultUserPreferences,
	metricsOpts,
	getStayLength,
}
