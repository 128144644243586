import DataTableComponent from "../components/DataTableComponent";
import { columnsBookingsLines, columnsBookinfsLinesMetrics, defaultFilters, defaultUserPreferences } from "../utils/booking-lines-data";
import { useEffect, useState } from 'react';
import { getDataApi, getOptions } from "../services/apiService";
import useApp from '../utils/useApp';
import Filters from "../components/filters/filters";
let hasRendered = false

const BookingLinesPage = () => {
    const [dataBookingLines, setDataBookingLines] = useState([])
    const [totalRows, setTotalRows] = useState(0)
    const [perPage, setPerPage] = useState(100)
    const [filters, setFilters] = useState(defaultFilters)
	const [userPreferences, setUserPreferences] = useState(defaultUserPreferences)
	const [[loData, loMetrics, loGraphic], setLoading] = useState([1,1,1])

    const {state: { user }, actions: { updateUser }} = useApp()

    const getBookingsLinesData = async (page, limit = perPage, sortDirection = 'desc', sortField = 'id', searchTerm = null) => {
        setLoading((x)=>{
            let newArr = [...x]
            newArr[0] = 1
            return newArr
        })
        console.log(filters)
        const offset = (page - 1) * limit
        const result = await getDataApi(user.lastVenue, 'bookingLine', offset, limit, null, null, sortField, sortDirection, searchTerm, filters)
        // const result = await getDataApi(user.lastVenue, 'bookingLine', offset, limit, null, null, sortField, sortDirection, searchTerm, filters)
        const BookingsLinesDataResponse = Object.values(result.data)
            .map((bookingLine) => {
                return {
                    id: bookingLine.id,
                    code: bookingLine.code,
                    desc: bookingLine.desc,
                    type: bookingLine.type,
                    zone: bookingLine.zone,
                    qty: bookingLine.qty,
                    price: bookingLine.price,
                    disc: bookingLine.disc,
                    bookingId: bookingLine.bookingId,
                    createdAt: bookingLine.createdAt,
                    updatedAt: bookingLine.updatedAt,
                    productId: bookingLine.productId,
                    // group: bookingLine.group,
                    // subGroup: bookingLine.subGroup
                }
            })
        setDataBookingLines(BookingsLinesDataResponse)
        setTotalRows(result.count)
        setLoading((x)=>{
            let newArr = [...x]
            newArr[0] = 0
            newArr[1] = 0
            return newArr
        })
    }

    const handleRefresh = (f) => {
        setLoading([1,1,1])
        getBookingsLinesData(1, perPage, 'desc', 'id', null, f)
    }

    const config = {
		page: 'bookingsLinesPage',
		localStorageItem: 'booking-lines-filters',
		filtersInterface: defaultFilters,
		preferencesInterface: defaultUserPreferences,
		rows: [
			[
				{role: 'layout', inner: 'LINEAS DE PRODUCTO'},
				[
					{key: 'update', action: handleRefresh},
				]   
			],
            [
                {key: 'createdAt', type: 'datetime-local', label: 'Creación', role: 'filter'},
                {key: 'updatedAt', type: 'datetime-local', label: 'Modificación', role: 'filter'}
            ],
            [
                {
                    id: 'slct-type',
                    key: 'type',
                    type: 'select',
                    role: 'filter',
                    placeholder: 'Tipo',
                    preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
                    optionsAndTags: async () => {
                        let options = await getOptions('type')
                        if (options.type===undefined) return [[],[]]
                        return [options.type.map((opt) => ({name: opt.type===null || opt.type==='' ? '-' : opt.type, value: opt.type})),[]]
                    }
                },
                {
                    id: 'slct-status',
                    key: 'status',
                    type: 'select',
                    role: 'filter',
                    placeholder: 'Estado',
                    preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
                    optionsAndTags: async () => {
                        let options = await getOptions('status')
                        if (options.status===undefined) return [[],[]]
                        return [options.status.map((opt) => ({name: opt.status===null || opt.status==='' ? '-' : opt.status, value: opt.status})),[]]
                    }
                },
                {
                    id: 'slct-userId',
                    key: 'userId',
                    type: 'select',
                    role: 'filter',
                    placeholder: 'Usuario',
                    preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects', 'searchable'],
                    optionsAndTags: async () => {
                        let options = await getOptions('userId')
                        if (options.userId===undefined) return [[],[]]
                        return [options.userId.map((opt) => ({name: `${opt.name} (${opt.id})`, value: opt.id})),[]]
                    }
                },
                {
                    id: 'slct-product-id',
                    key: 'productId',
                    type: 'select',
                    role: 'filter',
                    placeholder: 'Producto',
                    preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'searchable','toggleSelects'],
                    optionsAndTags: async () => {
                        let options = await getOptions('productId')
                        if (options.productId===undefined) return [[],[]]
                        return [options.productId.map((opt) => ({name: `${opt.name}(${opt.id})`, value: opt.id})),[]]
                    }
                },
                {
                    id: 'slct-zone',
                    key: 'zone',
                    type: 'select',
                    role: 'filter',
                    placeholder: 'Zona',
                    preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
                    optionsAndTags: async () => {
                        let options = await getOptions('zone')
                        if (options.zone===undefined) return [[],[]]
                        return [options.zone.map((opt) => ({name: opt.zone===null || opt.zone==='' ? '-' : opt.zone, value: opt.zone})),[]]
                    }
                },
            ],[
                [{key: 'autoFilters'}], [{key: 'clearFilters'}, {key: 'applyFilters'}]
            ]
		],
	}

    useEffect(() => { 
		if (hasRendered) {
			handleRefresh(filters) 
			updateUser({...user, lastVenue: filters.venues})
		}
		else hasRendered = true
	}, [filters])

    return (
        <div className="card">
            <div className="card-body">
                <Filters 
					config={config} 
					onFilterChange={(e) => setFilters(e)}
                    onPreferenceChange={(e) => setUserPreferences(e)}
				/>
                <hr />
                <DataTableComponent
                    title="Listado de líneas de producto"
                    description=""
                    columns={columnsBookingsLines}
                    data={dataBookingLines}
                    setPerPage={setPerPage}
                    loading={loData === 1}
                    getData={getBookingsLinesData}
                    totalRows={totalRows}
                    perPage={perPage}
                    model="bookingLine"
                />
            </div>
        </div>
    )
}
export default BookingLinesPage;
