import { useState, useEffect } from "react"
import InputSelect from "../InputSelect/InputSelect"

const InputSelectWrapper = ({config, filters, onInput, clearFilters, disable}) => {

    const [options, setOptions] = useState([])
    const [tags, setTags] = useState([])

    const setUp = async () => {
        let result = await config.optionsAndTags()
        return result
    }

    useEffect(()=>{
        setUp().then(([options, tags]) => {
            setOptions(options)
            setTags(tags)
        })
    },[])

    return (
        <InputSelect
            selectId={config.id}
            name={config.key}
            placeholder={config.placeholder}
            onInput={onInput}
            options={options}
            setOptions={setOptions}
            tags={tags}
            label={config.label ? config.label : ''}
            setTags={setTags}
            defaultValue={filters[config.key]}
            searchable={config.preferences.includes('searchable')}
            showCurrentValue={config.preferences.includes('showCurrentValue')}
            showCheckedValuesTag={config.preferences.includes('showCheckedValuesTag')}
            multiSelect={config.preferences.includes('multiSelect')}
            selectAllOption={config.preferences.includes('selectAllOption')}
            autoHide={config.preferences.includes('autoHide')}
            toggleSelects={config.preferences.includes('toggleSelects')}
            onlyOne={config.preferences.includes('onlyOne')}
            clearFilters={clearFilters}

            disable={disable}
        />
    )
}

export default InputSelectWrapper