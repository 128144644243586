

import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const numberWithSpaces = (x) => {
	const n = String(x.toFixed(2)).split('.')
	return n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ',' + n.slice(1)
}
const PriceFormatter = ({num}) => {
	return (<span className="price-tag">{num ? numberWithSpaces(num) + '€' : '0'}</span>)
}

const WeekData = ({formData, gm}) => {

    return formData.length > 0 ? (
                <div className='expandable-component weekData'>
                    <ul>
                        { formData
                        .sort((a, b) =>{ 
                            return Number(a[gm].substring(0, 2)) - Number(b[gm].substring(0, 2))
                        })
                        .map((bookingSum,i)=>{

                            return (
                                <li key={i}>
                                    <div><span>{bookingSum[gm]}</span></div>
                                    <div><span>{bookingSum.pax}</span></div>
                                    <div><span>{bookingSum.count}</span></div>
                                    <div><PriceFormatter num={bookingSum.salesAmount}/></div>
                                    <div><PriceFormatter num={bookingSum.aftersalesAmount}/></div>
                                    <div><PriceFormatter num={bookingSum.price}/></div>
                                </li>
                            )
                        })}
                    </ul>
                </div>
    )
    :
    null

}

export default WeekData
