import moment from 'moment'
import { getOptions } from '../services/apiService'
import 'moment/locale/es'
moment.locale('es')

const columnsRawTracking = [
	{
		name: 'id',
		selector: (row) => row.id,
		sortable: true,
		width: '60px',
	},
	{
		name: 'cookie',
		selector: (row) => row.rmbid,
		sortable: true,
		width: '200px',
		style: {
			paddingRight: '20px',
		},
	},
	{
		name: 'event',
		selector: (row) => row.event,
		sortable: true,
		width: '100px',
	},
	{
		name: 'source',
		selector: (row) => row.source,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Medium',
		selector: (row) => row.medium,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Campaign',
		selector: (row) => row.campaign,
		sortable: true,
		width: '150px',
	},
	{
		name: 'Term',
		selector: (row) => row.term,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Referer',
		selector: (row) => row.referer,
		sortable: true,
		width: '200px',
		style: {
			paddingRight: '20px',
		},
	},
	{
		name: 'Agent',
		selector: (row) => row.agent,
		sortable: true,
		width: '200px',
		style: {
			paddingRight: '20px',
		},
	},
	{
		name: 'ip',
		selector: (row) => row.ip,
		sortable: true,
		width: '150px',
	},
	{
		name: 'Created At',
		selector: (row) => row.createdAt,
		sortable: true,
		cell: (props) => {
			return moment(props.createdAt).fromNow()
		},
		width: '150px',
	},
	{
		name: 'Updated At',
		selector: (row) => row.updatedAt,
		sortable: true,
		cell: (props) => {
			return moment(props.updatedAt).fromNow()
		},
		width: '150px',
	},
]

const columnsComputedTracking = [
	{
		name: 'id',
		selector: (row) => row.rmbid,
		sortable: true,
		width: '300px',
		style: {
			paddingRight: '20px',
		},
	},
	{
		name: 'Locator',
		selector: (row) => row.locator,
		sortable: true,
		width: '200px',
		style: {
			paddingRight: '20px',
		},
	},
	{
		name: 'Sales',
		selector: (row) => row.salesAmount,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Aftersales',
		selector: (row) => row.aftersalesAmount,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Status',
		selector: (row) => row.status,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Source',
		selector: (row) => row.source,
		sortable: true,
		style: {
			paddingRight: '20px',
		},
		width: '200px',
	},
	{
		name: 'Medium',
		selector: (row) => row.medium,
		sortable: true,
		style: {
			paddingRight: '20px',
		},
		width: '200px',
	},
	{
		name: 'Campaign',
		selector: (row) => row.campaign,
		sortable: true,
		width: '200px',
	},
	{
		name: 'Date',
		selector: (row) => row.date,
		sortable: true,
		cell: (props) => {
			return moment(props.date).fromNow()
		},
		width: '150px',
	},
	{
		name: 'Created At',
		selector: (row) => row.createdAt,
		sortable: true,
		cell: (props) => {
			return moment(props.createdAt).fromNow()
		},
		width: '150px',
	},
	{
		name: 'Notes',
		selector: (row) => row.notes,
		sortable: true,
		width: '200px',
	},
]
function getMonday(date) {
	let d = date ? new Date(date) : new Date()
	const day = d.getDay()
	const diff = d.getDate() - day + (day === 0 ? -6 : 1)
	return new Date(d.setDate(diff))
}
const numberWithSpaces = (x, f = 2) => {
	const n = String(x.toFixed(f)).split('.')
	return n[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') + (f === 0 ? '' : ',' + n.slice(1))
}
const getDefaultDates = (mode, dts) => {
	const [dt1, dt2] = dts.map((dt) => new Date(dt))

	return mode === 1
		? [parseDate(new Date(dt1.getFullYear(), 0, 1), 0), parseDate(new Date(dt1.getFullYear(), 11, 31), 1)]
		: mode === 2
		? [
				parseDate(new Date(dt1.getFullYear(), new Date(dt1).getMonth(), 1), 0),
				parseDate(new Date(dt1.getFullYear(), new Date(dt1).getMonth() + 1, 0), 1),
		  ]
		: mode === 3
		? [
				parseDate(new Date(dt1.getFullYear(), new Date(dt1).getMonth(), 1), 0),
				parseDate(new Date(dt1.getFullYear(), new Date(dt1).getMonth(), 1), 1),
		  ]
		: [parseDate(getMonday(dt1), 0), parseDate(new Date(getMonday(dt1).setDate(getMonday(dt1).getDate() + 6)), 1)]
}
const goBackward = (mode, dts) => {
	const [dt1, dt2] = dts.map((dt) => new Date(dt))

	return mode === 1
		? [parseDate(new Date(dt1.getFullYear() - 1, 0, 1), 0), parseDate(new Date(dt2.getFullYear() - 1, 11, 31), 1)]
		: mode === 2
		? [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth() - 1, 1), 0),
				parseDate(new Date(dt2.getFullYear(), dt2.getMonth(), 0), 1),
		  ]
		: mode === 3
		? [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() - 1), 0),
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() - 1), 1),
		  ]
		: [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() - 7), 0),
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() - 1), 1),
		  ]
}
const goForward = (mode, dts) => {
	const [dt1, dt2] = dts.map((dt) => new Date(dt))

	return mode === 1
		? [parseDate(new Date(dt1.getFullYear() + 1, 0, 1), 0), parseDate(new Date(dt2.getFullYear() + 1, 11, 31), 1)]
		: mode === 2
		? [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth() + 1, 1), 0),
				parseDate(new Date(dt2.getFullYear(), dt2.getMonth() + 2, 0), 1),
		  ]
		: mode === 3
		? [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() + 1), 0),
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() + 1), 1),
		  ]
		: [
				parseDate(new Date(dt2.getFullYear(), dt2.getMonth(), dt2.getDate() + 1), 0),
				parseDate(new Date(dt2.getFullYear(), dt2.getMonth(), dt2.getDate() + 7), 1),
		  ]
}

const parseDate = (d = Date.prototype, i) => {
	let l = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().substring(0, 10)
	l += i ? 'T23:59' : 'T00:00'
	l = l.replace('T', ' ')
	return l
}

const defaultFilters = {
	venueId: 0,
	mode: 1,
	createdAt: [
		parseDate(new Date(new Date().getFullYear(), 0, 1), 0),
		parseDate(new Date(new Date().getFullYear(), 11, 31), 1),
	],
}
const icons = ['ti-arrow-down down', 'ti-equal equal', 'ti-arrow-up up']

const config = {
	page: 'trackingsPage',
	filtersInterface: {
		venueId: 0,
		source: [],
		medium: [],
		campaign: [],
		mode: 2,
		createdAt: [
			parseDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1), 0),
			parseDate(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0), 1),
		],
	},
	localStorageItem: 'trackings-filters',
	preferencesInterface: { autoFilters: 0 },
	rows: [
		[
			{
				id: 'slct-tablao',
				key: 'venueId',
				type: 'select',
				role: 'filter',
				label: 'Tablao',
				preferences: ['onlyOne', 'autoHide', 'showCurrentValue', 'toggleSelects'],
				optionsAndTags: () => [
					[
						{ value: 0, name: 'Todos' },
						{ value: 1, name: 'Cardamomo' },
						{ value: 2, name: '1911' },
					],
					[],
				],
			},
			[
				{
					id: 'date_controller',
					key: 'createdAt',
					type: 'date_arrows',
					dateOpts: { year: 'numeric', month: 'short', day: 'numeric' },
					basedOn: 'mode',
				},
			],
		],
		[
			{
				id: 'slct-source',
				key: 'source',
				type: 'select',
				role: 'filter',
				placeholder: 'Source',
				preferences: ['selectAllOption', 'showCheckedValuesTag', 'onlyOne', 'toggleSelects'],
				optionsAndTags: async () => {
					let options = await getOptions('source')
					return [
						options.source.map(({ source }) => {
							let name = source
								.replace(/%20/gi, ' ') // Reemplaza %20 por espacio
								.replace(/%2B/gi, '+') // Reemplaza %2B por +
								.replace(/%2F/gi, '/') // Reemplaza %2F por /
								.replace(/%3A/gi, ':') // Reemplaza %3A por :
								.replace(/%3F/gi, '?') // Reemplaza %3F por ?
								.replace(/%3D/gi, '=') // Reemplaza %3D por =
							return { name: source === '' ? 'Ninguno' : name, value: source === '' ? 'empty' : source }
						}),
						[],
					]
				},
			},
			{
				id: 'slct-medium',
				key: 'medium',
				type: 'select',
				role: 'filter',
				placeholder: 'Medium',
				preferences: ['selectAllOption', 'showCheckedValuesTag', 'onlyOne', 'toggleSelects'],
				optionsAndTags: async () => {
					let options = await getOptions('medium')
					return [
						options.medium.map(({ medium }) => {
							let name = medium
								.replace(/%20/gi, ' ') // Reemplaza %20 por espacio
								.replace(/%2B/gi, '+') // Reemplaza %2B por +
								.replace(/%2F/gi, '/') // Reemplaza %2F por /
								.replace(/%3A/gi, ':') // Reemplaza %3A por :
								.replace(/%3F/gi, '?') // Reemplaza %3F por ?
								.replace(/%3D/gi, '=') // Reemplaza %3D por =
							return { name: medium === '' ? 'Ninguno' : name, value: medium === '' ? 'empty' : medium }
						}),
						[],
					]
				},
			},
			{
				id: 'slct-campaign',
				key: 'campaign',
				type: 'select',
				role: 'filter',
				placeholder: 'campaign',
				preferences: ['selectAllOption', 'showCheckedValuesTag', 'onlyOne', 'toggleSelects'],
				optionsAndTags: async () => {
					let options = await getOptions('campaign')
					return [
						options.campaign.map(({ campaign }) => {
							let name = campaign
								.replace(/%20/gi, ' ') // Reemplaza %20 por espacio
								.replace(/%2B/gi, '+') // Reemplaza %2B por +
								.replace(/%2F/gi, '/') // Reemplaza %2F por /
								.replace(/%3A/gi, ':') // Reemplaza %3A por :
								.replace(/%3F/gi, '?') // Reemplaza %3F por ?
								.replace(/%3D/gi, '=') // Reemplaza %3D por =
							return {
								name: campaign === '' ? 'Ninguno' : name,
								value: campaign === '' ? 'empty' : campaign,
							}
						}),
						[],
					]
				},
			},
			{
				id: 'slct-date-mode',
				key: 'mode',
				type: 'select',
				role: 'filter',
				placeholder: 'Visualización',
				preferences: ['onlyOne', 'autoHide', 'showCurrentValue', 'toggleSelects'],
				optionsAndTags: () => [
					[
						{ value: 1, name: 'Ver por año' },
						{ value: 2, name: 'Ver por mes' },
						{ value: 3, name: 'Ver por día' },
						{ value: 4, name: 'Ver por semana' },
					],
					[],
				],
			},
		],
		[{ key: 'autoFilters' }, [{ key: 'clearFilters' }, { key: 'applyFilters' }]],
	],
}
const defaultPreferences = {
	autoFilters: 0,
}
const getIconBasedOnCalc = (type, ...data) => {
	let calc = () => {}
	let [r1, r2] = [Number(data[0]), Number(data[1])]

	switch (type) {
		case 'conversion':
		case 'salePerVisitor':
			calc = (x, y) => Number(x) / Math.max(1, Number(y))
			r1 = calc(data[0], data[1])
			r2 = calc(data[2], data[3])
			break

		case 'unfinished':
			calc = (x, y) => (Number(x) - Number(y)) / Math.max(1, Number(x))
			r1 = calc(data[0], data[1])
			r2 = calc(data[2], data[3])
			break

		default:
			break
	}
	return r1 === r2 ? 1 : r1 > r2 ? 2 : 0
}
export {
	columnsRawTracking,
	columnsComputedTracking,
	getDefaultDates,
	goBackward,
	goForward,
	parseDate,
	defaultFilters,
	icons,
	numberWithSpaces,
	config,
	defaultPreferences,
	getMonday,
	getIconBasedOnCalc,
}
