import { useEffect, useState } from 'react'
import moment from 'moment'
import References from './OriginChildren'
import { getDataApi } from "../services/apiService"
import useApp from '../utils/useApp'
import 'moment/locale/es'
moment.locale('es')

const OriginForm = ({data, sendToUpdate, responseMessage}) => {

	const [fieldsToSend, setFieldsToSend] = useState([])
	const [parentOrigins, setParentOrigin] = useState([])
	const [formData, setFormData] = useState({
		name: '',
		parent: 0,
		order: 0,
		venueId: 0,
		commission: 0,
	})

	const { state:{ user }} = useApp()

	const getOriginData = async (page, limit = 20, sortDirection = 'asc', sortField = 'id') => {
		const offset = (page-1) * limit
		const result = await getDataApi(user.lastVenue, 'origin', offset, limit, null, null, sortField, sortDirection)
		
		const Parents = Object.values(result.data)
		.filter((origin) => origin.parent == 0 )
		.map((origin) => {
			return {
			id: origin.id,
			name: origin.name,
			parent: origin.parent,
			}
		})
		setParentOrigin(Parents)
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFieldsToSend(new Set([...fieldsToSend, name]))
		setFormData({...formData, [name]: name!=='name' ? Number(value) : value})
	}

	const handleSave = () => {

		if (fieldsToSend.size === 0) return
		sendToUpdate(formData)
	}

	useEffect(() => { 

		console.log(data)
		if (Object.keys(data).length > 0) setFormData(data) 

	}, [data])

	useEffect(()=>{ 

		getOriginData(1, 20, 'asc', 'parent') 

	},[])

	return (
		<form className="mt-4 px-4 origin_form">
			<div className="d-sm-flex align-items-center justify-content-between mb-3">
				<div className="form-group">
					<h6 className="mb-0 fs-4 mb-2">Id</h6>
					<div className="form-group">
						<input type="text" className="form-control" id="bookingId" placeholder="Id" defaultValue={formData.id} disabled readOnly></input>
					</div>
				</div>
				
			</div>
			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">{formData.parent == 0 ? "Origen" : "Referencia"}</h6>
				<input
					type="text"
					className="form-control"
					name='name'
					id="bookingName"
					defaultValue={formData.name}
					onChange={handleInputChange}
				/>
			</div>
			<div className="d-sm-flex align-items-center mb-3">
				<div className="form-group mt-4">
					<h6 className="mb-0 fs-4 mb-2">Padre</h6>
					<select onChange={handleInputChange} className="form-control" name="parent" value={formData.parent}>
						<option value="0">Sin padre</option>
						{
							parentOrigins.map((x) => {
								return <option key={x.id} value={x.id}>{x.name}</option>
							})
						}
					</select>
				</div>
				<div className="form-group mt-4 ps-3">
					<h6 className="mb-0 fs-4 mb-2">Orden</h6>
					<input
						type="text"
						className="form-control"
						id="bookingName"
						name='order'
						defaultValue={formData.order}
						onChange={handleInputChange}
					/>
				</div>
				<div className="form-group mt-4 ps-3">
					<h6 className="mb-0 fs-4 mb-2">Id Local</h6>
					<input
						type="text"
						className="form-control"
						id="bookingName"
						name='venueId'
						defaultValue={formData.venueId}
						onChange={handleInputChange}
					/>
				</div>
				<div className="form-group mt-4 ps-3">
					<h6 className="mb-0 fs-4 mb-2">Comisión</h6>
					<input
						type="text"
						className="form-control"
						id="bookingName"
						name='commission'
						defaultValue={formData.commission}
						onChange={handleInputChange}
					/>
				</div>
			</div>

			<div className="d-flex align-items-right fs-2 mt-4">
				Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}
			</div>
			<button type="button" className="mt-4 btn rmb-btn-primary" onClick={handleSave}>
				Guardar
			</button>
			{ formData.parent == 0 &&
				<References formData={formData}/>
            }
			{responseMessage && (
				<div className="mt-4 alert alert-success" role="alert">
					{responseMessage}
				</div>
			)}

		</form>
		
	)
}

export default OriginForm;